.background-treino {
  content: '';
  left: 0;
  right: 0;
  z-index: 1;
  background: $verde;
  position: absolute;
  bottom: 0;
  opacity: 0;
  height: calc(100vh - 62px);
  animation: none;

  &.active {
    opacity: 1;
  }

  &.paused {
    animation-play-state: paused!important;
  }
}

@keyframes background {
  0% { height: calc(100vh - 62px); opacity: 0;}
  1% { opacity: 1; }
  99% { opacity: 1; }
  100% { height: 0vh; opacity: 0; }
}