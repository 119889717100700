@import 'utils/variables';
@import 'utils/mixins';
@import 'utils/helpers';
@import 'utils/utils.scss';
@import 'layout/components';
@import 'layout/sidebar';
@import 'layout/content';
@import 'layout/background';
@import 'base/theme';
@import 'base/reset';
@import 'base/base';
@import 'base/fonts';
@import 'base/typography';
@import 'components/loading';
@import 'components/buttons';
@import 'components/forms';
@import 'components/images';
@import 'layout/page';

body ul {
  list-style-type: none;
}

*:not(input) {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  user-select: text;
}

input:-webkit-autofill,
input:-webkit-autofill:active,
input:-webkit-autofill:focus,
input:-webkit-autofill:hover {
  -webkit-box-shadow: 0 0 0 1000px initial inset !important;
  -webkit-text-fill-color: inherit;
  transition: background-color 5000s ease-in-out 0s;
}

.m2app-dark input:-webkit-autofill,
.m2app-dark input:-webkit-autofill:active,
.m2app-dark input:-webkit-autofill:focus,
.m2app-dark input:-webkit-autofill:hover {
  -webkit-box-shadow: 0 0 0 1000px #0d121f inset !important;
  -webkit-text-fill-color: white;
  background: #0d121f;
  transition: background-color 5000s ease-in-out 0s;
}

.clearfix:after,
.clearfix:before {
  content: " ";
  /* 1 */
  display: table;
  /* 2 */
}

.clearfix:after {
  clear: both;
}

.animated {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.bg-cinza-logo {
  background-color: #0c1420 !important;
}

.text-cinza-logo {
  color: #0c1420 !important;
}

.display-none {
  display: none;
}

.md-scroll-mask {
  position: initial;
}

a,
button {
  -ms-touch-action: manipulation;
  /* IE10  */
  touch-action: manipulation;
  /* IE11+ */
}

.justify-content {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
}

.cdk-overlay-pane {
  width: 100%;
}