.text-left { text-align: left; }
.text-center { text-align: center; }
.text-right { text-align: right; }

.text-primary { color: $primary-color; }
.text-error { color: $error-color; }
.text-success { color: $success-color; }
.text-muted { color: $muted-color; }

.text-bold { font-weight: bold; }

.mt-0 { margin-top: 0; }
.mt-10 { margin-top: 10px; }
.mt-20 { margin-top: 20px; }
.mt-30 { margin-top: 30px; }
.mt-40 { margin-top: 40px; }
.mt-50 { margin-top: 50px; }

.ml-10 { margin-left: 10px; }
.ml-20 { margin-left: 20px; }
.ml-30 { margin-left: 30px; }
.ml-40 { margin-left: 40px; }
.ml-50 { margin-left: 50px; }

.mr-10 { margin-right: 10px; }
.mr-20 { margin-right: 20px; }
.mr-30 { margin-right: 30px; }
.mr-40 { margin-right: 40px; }
.mr-50 { margin-right: 50px; }

.mb-10 { margin-bottom: 10px; }
.mb-20 { margin-bottom: 20px; }
.mb-30 { margin-bottom: 30px; }
.mb-40 { margin-bottom: 40px; }
.mb-50 { margin-bottom: 50px; }

.m-t-1 { margin-top: 1px !important; }
.m-t-2 { margin-top: 2px !important; }
.m-t-4 { margin-top: 4px !important; }
.m-t-5 { margin-top: 5px !important; }

.m-t-10 { margin-top: 10px !important; }
.m-t-15 { margin-top: 15px !important; }
.m-t-20 { margin-top: 20px !important; }
.m-t-25 { margin-top: 25px !important; }
.m-t-30 { margin-top: 30px !important; }

.m-b-1 { margin-bottom: 1px !important; }
.m-b-2 { margin-bottom: 2px !important; }
.m-b-4 { margin-bottom: 4px !important; }
.m-b-5 { margin-bottom: 5px !important; }

.m-b-10 { margin-bottom: 10px !important; }
.m-b-15 { margin-bottom: 15px !important; }
.m-b-20 { margin-bottom: 20px !important; }
.m-b-25 { margin-bottom: 25px !important; }
.m-b-30 { margin-bottom: 30px !important; }

.m-l-1 { margin-left: 1px !important; }
.m-l-2 { margin-left: 2px !important; }
.m-l-4 { margin-left: 4px !important; }
.m-l-5 { margin-left: 5px !important; }

.m-l-10 { margin-left: 10px !important; }
.m-l-15 { margin-left: 15px !important; }
.m-l-20 { margin-left: 20px !important; }
.m-l-25 { margin-left: 25px !important; }
.m-l-30 { margin-left: 30px !important; }

.m-r-1 { margin-right: 1px !important; }
.m-r-2 { margin-right: 2px !important; }
.m-r-4 { margin-right: 4px !important; }
.m-r-5 { margin-right: 5px !important; }

.m-r-10 { margin-right: 10px !important; }
.m-r-15 { margin-right: 15px !important; }
.m-r-20 { margin-right: 20px !important; }
.m-r-25 { margin-right: 25px !important; }
.m-r-30 { margin-right: 30px !important; }

.br-1 { border-radius: 1px !important; }
.br-2 { border-radius: 2px !important; }
.br-4 { border-radius: 4px !important; }
.br-5 { border-radius: 5px !important; }

.br-10 { border-radius: 10px !important; }
.br-15 { border-radius: 15px !important; }
.br-20 { border-radius: 20px !important; }
.br-25 { border-radius: 25px !important; }
.br-30 { border-radius: 30px !important; }

.p-t-1 { padding-top: 1px !important; }
.p-t-2 { padding-top: 2px !important; }
.p-t-4 { padding-top: 4px !important; }
.p-t-5 { padding-top: 5px !important; }

.p-t-10 { padding-top: 10px !important; }
.p-t-15 { padding-top: 15px !important; }
.p-t-20 { padding-top: 20px !important; }
.p-t-25 { padding-top: 25px !important; }
.p-t-30 { padding-top: 30px !important; }

.p-b-1 { padding-bottom: 1px !important; }
.p-b-2 { padding-bottom: 2px !important; }
.p-b-4 { padding-bottom: 4px !important; }
.p-b-5 { padding-bottom: 5px !important; }

.p-b-10 { padding-bottom: 10px !important; }
.p-b-15 { padding-bottom: 15px !important; }
.p-b-20 { padding-bottom: 20px !important; }
.p-b-25 { padding-bottom: 25px !important; }
.p-b-30 { padding-bottom: 30px !important; }

.p-l-1 { padding-left: 1px !important; }
.p-l-2 { padding-left: 2px !important; }
.p-l-4 { padding-left: 4px !important; }
.p-l-5 { padding-left: 5px !important; }

.p-l-10 { padding-left: 10px !important; }
.p-l-15 { padding-left: 15px !important; }
.p-l-20 { padding-left: 20px !important; }
.p-l-25 { padding-left: 25px !important; }
.p-l-30 { padding-left: 30px !important; }

.p-r-1 { padding-right: 1px !important; }
.p-r-2 { padding-right: 2px !important; }
.p-r-4 { padding-right: 4px !important; }
.p-r-5 { padding-right: 5px !important; }

.p-r-10 { padding-right: 10px !important; }
.p-r-15 { padding-right: 15px !important; }
.p-r-20 { padding-right: 20px !important; }
.p-r-25 { padding-right: 25px !important; }
.p-r-30 { padding-right: 30px !important; }
.p-r-50 { padding-right: 50px !important; }

.texto-verde-500 { color: $verde-500; }
.texto-verde-400 { color: $verde-400; }
.texto-verde-300 { color: $verde-300; }
.texto-verde-200 { color: $verde-200; }
.texto-verde-100 { color: $verde-100; }

.bg-verde-500 { background-color: $verde-500; }
.bg-verde-400 { background-color: $verde-400; }
.bg-verde-300 { background-color: $verde-300; }
.bg-verde-200 { background-color: $verde-200; }
.bg-verde-100 { background-color: $verde-100; }

.texto-cinza-500 { color: $cinza-500; }
.texto-cinza-400 { color: $cinza-400; }
.texto-cinza-300 { color: $cinza-300; }
.texto-cinza-200 { color: $cinza-200; }
.texto-cinza-100 { color: $cinza-100; }

.bg-cinza-500 { background-color: $cinza-500; }
.bg-cinza-400 { background-color: $cinza-400; }
.bg-cinza-300 { background-color: $cinza-300; }
.bg-cinza-200 { background-color: $cinza-200; }
.bg-cinza-100 { background-color: $cinza-100; }
