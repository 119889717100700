body.openSidebar {
  .sidebar {
    transform: translate(0, 0);

    @media screen and (min-width: 769px) {
      transform: translate(-350px, -50%);
    }
  }
}

.sidebar {
  position: absolute;
  z-index: 30;
  top: 53px;
  left: 0;
  transform: translate(-100%, 0);
  height: calc(100% - 62px);
  overflow-y: auto;
  background-color: $sidebar-fundo;
  width: 100%;
  padding: 2.3rem;
  transition: transform .4s ease;

  .sidebar-menu {
    margin-top: 40px;

    h4 {
      margin-bottom: 10px;
    }

    a {
      text-decoration: none;
      display: block;
      color: $branco;
      padding: 10px 0;
    }
  }

  .form-group {
    display: flex;
    flex: 1;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 3rem;

    .form-border {
      flex: 0 0 100%;
      border-bottom: 2px solid $muted;
      transition: border-color .4s ease;
    }

    label {
      @extend .text-muted;
      display: flex;
      flex: 0;
      white-space: nowrap;
      padding: 1rem 0;
    }

    input {
      display: flex;
      background: none;
      border: none;
      box-shadow: none;
      font-size: 1.1rem;
      text-align: right;
      color: $branco;
      outline: none!important;
      width: 127px;

      &:focus + .form-border,
      &:active + .form-border {
        border-color: $verde;
      }

      &:disabled {
        @extend .text-muted;
      }
    }
  }

  .form-checkbox {
    input {
      visibility: hidden;
      opacity: 0;
      pointer-events: none;
      position: absolute;
    }

    label {
      position: relative;
      flex: 1;
      cursor: pointer;

      &:after {
        content: '';
        position: absolute;
        top: 50%;
        right: 0;
        width: 15px;
        height: 15px;
        border-radius: 4px;
        background-color: transparent;
        border: 1px solid $muted;
        transform: translate(0, -50%);
      }
    }

    input:checked + label:after {
      background-color: $verde;
      border-color: $verde;
    }
  }

  h4 {
    margin: 0 0 1.5rem 0;
  }
  
  .btn-divider{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }

  .btn-sidebar {
    border-color: $verde;
    // padding-left: 0.5rem;
    // padding-right: 0.5rem;
    padding: 0.5rem;
    border-radius: 5px;
    background-color: transparent;
    color: white;
    font-size: 1rem;
    font-weight: bold;
    


    &:hover {
      color: $fundo;
      background-color: $verde;
    }
  }

  @media screen and (min-width: 600px) {
    width: 50%;
  }

  @media screen and (min-width: 100px) {
    top: 48%;
    left: 10px;
    right: 10px;
    max-height: calc(100% - 80px);
    transform: translate(-110%, -50%);
    width: 100%;
    max-width: calc(100% - 20px);
    border-radius: 10px;




    &.openOnHover {
      transform: translate(0, -50%);
    }

    .sidebar-menu {
      display: none;
    }
  }
}

::-webkit-input-placeholder { /* Chrome */
  color: $muted;
}
:-ms-input-placeholder { /* IE 10+ */
  color: $muted;
}
::-moz-placeholder { /* Firefox 19+ */
  color: $muted;
  opacity: 1;
}
:-moz-placeholder { /* Firefox 4 - 18 */
  color: $muted;
  opacity: 1;
}

app-buttonsidebar {
  position: absolute;
  bottom: -3px;
  right: 7px;
  width: 37px;
  height: 53px;
  z-index: 50;
  margin: 10px;
}
