.btn-timers {
  font-size: 1rem;
  cursor: pointer;
  font-weight: bold;
  border: 2px solid;
  border-radius: 4px;
  padding: 0.7rem 2.5rem;
  margin: 0 0 1.5rem 0;
  transition: background-color .2s ease, color .2s ease-in-out;

  &-block {
    display: block;
    width: 100%;
  }

  &-primary {
    background-color: $verde;
    border-color: $verde;
    color: $fundo;
    width: 10rem;

    &:hover {
      border-color: darken($verde, 10%);
      background-color: darken($verde, 10%);
    }
  }

  &-ghost {
    width: 10rem;
    background: none;
    border-color: $branco;
    color: $branco;

    &:hover {
      color: $fundo;
      background-color: $branco;
    }
  }
}

