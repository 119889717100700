.logo {
  width: 150px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.image-icon {
  width: 60px;

  @include md {
    width: 100px;
  }
}
