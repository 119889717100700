.form {
  display: flex;
  flex-direction: column;
}

.form__group {
  position: relative;
}

.form__has-error {
  &,
  label a,
  input:not(:focus),
  select:not(:focus), {
    border-color: $error-color!important;
    color: $error-color!important;
  }
}

.form__group--inline {
  input, label {
    display: inline-block;
    width: auto;
  }
}

.form__control {
  text-align: left;
  display: block;
  height: 34px;
  width: 100%;
  line-height: 1.42857143;
  background-image: none;
  border-radius: 4px;
  background-color: #f2f2f2;
  border: 1px solid #fff;
  padding: 10px 12px;
  min-height: 41px;
  margin-bottom: 10px;
  box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
}

.form__checkbox {
  text-align: left;
  color: $gray;
}

.form__error {
  color: $error-color;
  text-align: left;
}

.form__link {
  display: block;
  margin: 15px 0;
  text-decoration: underline;
}

// remover setas dos input [type=number]
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

form .container-checkbox .checkbox-error .mat-checkbox-frame {
  border-color: #f44336 !important;
}
