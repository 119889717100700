@mixin xs {
  @media all and (min-width: $xs) {
    @content;
  }
}

@mixin sm {
  @media all and (min-width: $sm) {
    @content;
  }
}

@mixin md {
  @media all and (min-width: $md) {
    @content;
  }
}

@mixin lg {
  @media all and (min-width: $lg) {
    @content;
  }
}

@mixin breakpoint($from, $to: 0) {
  @if $to > $from {
    @media all and (min-width: $from) and (max-width: $to) {
      @content;
    }
  }

  @else {
    @media all and (min-width: $from) {
      @content;
    }
  }
}

@mixin clearfix {
  *zoom: 1;
  &:before, &:after {
    display: table;

    content: " ";
  }
  &:after {
    clear: both;
  }
}

@mixin vertical-align {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

@mixin horizontal-align {
  display: block;
  margin: 0 auto;
  text-align: center;
  align-items: center;
  align-content: center;
  justify-content: center;
}

@mixin titulo-actuar-light {
  font-size: 52px;
  font-weight: 100;
  font-family: actuarlight, sans-serif;
  @media (max-width: 767px) {
    font-size: 30px;
    font-weight: 100;
    font-family: actuarlight, sans-serif;
  }
}

@mixin titulo-actuar-bold {
  font-size: 3.25em;
  font-weight: 900;
  font-family: actuarregular, sans-serif;
  @media (max-width: 767px) {
    font-size: 1.875em;
    font-weight: 900;
    font-family: actuarregular, sans-serif;
  }
}

@mixin paragrafo-actuar-light {
  font-weight: 100;
  font-size: 22px;
  font-family: actuarlight, sans-serif;
  line-height: 1.5;
  @media (max-width: 767px) {
    font-size: 18px;
    font-weight: 100;
    font-family: actuarlight, sans-serif;
  }
}