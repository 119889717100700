
.btn {
  font-family: 'Roboto', Helvetica, Arial, sans-serif;
  border: none;
  border-radius: 5px !important;
  color: #000;
  position: relative;
  height: 36px;
  margin: 0;
  min-width: 64px;
  padding: 0 16px;
  display: inline-block;
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 0;
  overflow: hidden;
  will-change: box-shadow;
  outline: 0;
  cursor: pointer;
  text-decoration: none;
  text-align: center;
  line-height: 36px;
  background-color: #4ce364;

  transition:
    box-shadow .2s cubic-bezier(.4, 0, 1, 1),
    background-color .2s cubic-bezier(.4, 0, .2, 1),
    color .2s cubic-bezier(.4, 0, .2, 1);

  &.btn-default,
  &.disabled,
  &:disabled {
    background-color: #dfdfdf;
    border-color: #adadad;
    color: #2b3038;
  }

  &.danger {
    background-color: #ff6352;
    border-color: #adadad;
    color: white;
  }

  &.btn-primary {
    color: white;
  }
}
