::-webkit-scrollbar {
  width: 0.6em;
  background: #fff;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: #D1D3D4;
}

::-webkit-scrollbar-track {
  border-radius: 10px;
}

@keyframes fadeInUp {
  from {
    transform: translate3d(0, 150px, 0)
  }
  to {
    transform: translate3d(0, 0, 0);
    opacity: 1
  }
}
