@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: local('Roboto Light'), local('Roboto-Light'), url('../../../assets/fonts/roboto/roboto-light.woff') format('woff');
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: local('Roboto'), local('Roboto-Regular'), url('../../../assets/fonts/roboto/roboto-regular.woff') format('woff');
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  src: local('Roboto Medium'), local('Roboto-Medium'), url('../../../assets/fonts/roboto/roboto-medium.woff') format('woff');
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  src: local('Roboto Bold'), local('Roboto-Bold'), url('../../../assets/fonts/roboto/roboto-bold.woff') format('woff');
}

@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url('../../../assets/fonts/material-icons/MaterialIcons-Regular.eot');
  src: url('../../../assets/fonts/material-icons/MaterialIcons-Regular.eot?#iefix') format('embedded-opentype'),
    url('../../../assets/fonts/material-icons/MaterialIcons-Regular.woff2') format('woff2'),
    url('../../../assets/fonts/material-icons/MaterialIcons-Regular.woff') format('woff'),
    url('../../../assets/fonts/material-icons/MaterialIcons-Regular.ttf') format('truetype'),
    url('../../../assets/fonts/material-icons/MaterialIcons-Regular.svg#actuarlight') format('svg');
}

.material-icons {
  font-family: 'Material Icons', sans-serif;
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  text-rendering: optimizeLegibility !important;
  -webkit-font-smoothing: antialiased;
}
