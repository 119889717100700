/*
*  Colors
*----------------------- */
$white: #fff;
$gray: #888;
$black: #000;

$text-color: #282d37;
$heading-color: #111;

$primary-color: #4794cf;
$error-color: #ff6666;
$success-color: #27ae60;
$muted-color: $gray;

$heading-color: #51555e;
$link-color: $primary-color;

$afig: #4ac204;
$treino: #4ce364;
$actuar-web: #7760bd;

/* Tons de verde */
$verde-500: #006633; // dark
$verde-400: #00ad59;
$verde-300: #00cc66;
$verde-200: #00e075; // light
$verde-100: #4ce364; // neon
$verde: #00c853;


/* Tons de cinza */
$cinza-500: #282d37;
$cinza-400: #999999;
$cinza-300: #cccccc;
$cinza-200: #e6e6e6;
$cinza-100: #f2f2f2;

$cinza-bg: #0d121f;

/*
*  Typography
*----------------------- */
$font-family: 'Roboto', sans-serif;
$font-root: 16px;
$font-weight: 300;
$font-line-height: 1.3 !default;
$font-size: 0.9rem !default;

$font-line-height-sm: 1.5 !default;
$font-size-sm: 1rem !default;

/*
*  Grid
*----------------------- */
$xs: 0;
$sm: 660px;
$md: 767px;
$lg: 992px;
$xg: 1100px;

/* Timers */
$branco: #ffffff;
$verde: #00ce62;
$vermelho: #ef5350;
$cinza: #353942;
$fundo: #0c1420;
$sidebar-fundo: #353942;
$muted: #8f9ab3;

$font-family-default:
  // Safari for OS X and iOS (San Francisco)
  -apple-system,
  // Chrome < 56 for OS X (San Francisco)
  BlinkMacSystemFont,
  // Windows
  "Segoe UI",
  // Android
  "Roboto",
  // Basic web fallback
  "Helvetica Neue", Arial, sans-serif,
  // Emoji fonts
  "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !default;