h1, h2, h3, h4, h5 {
  margin: 20px 0;
  font-weight: 300;
  color: $heading-color;
}

a {
  color: $link-color;

  &:hover {
    color: darken($link-color, 10%);
  }
}
