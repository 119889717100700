.main-timers {
  padding-top: 0 !important;
  font-size: 1rem;
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  position: relative;
 height: calc(100vh - 62px);
  z-index: 9;
  background-color: transparent !important;
}

.content-timers {
  margin: 20px auto 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 10px;

  .timer {
    font-size: 3rem;
    margin: 3rem .5rem;
    text-align: center;

    @media screen and (min-width: 600px) { font-size: 6rem; }
    @media screen and (min-width: 769px) { font-size: 7rem; }
    @media screen and (min-width: 1200px) { font-size: 8rem; }
    @media screen and (min-width: 1500px) { font-size: 10rem; }
  }

  .seconds {
    font-weight: 500;
  }

  .minutes {
    font-weight: 500;
  }

  .miliseconds {
    font-weight: 200;
  }

  .action {
    display: flex;
    align-items: center;
    justify-content: center;

    .btn {
      width: 135px;
      margin: 10px 10px 30px;
    }
  }
}
