* {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;

  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html, body {
  height: 100%;
}

html {
  font-size: $font-root;
  background-color: #020912;
}

body {
  color: $text-color;
  font-family: $font-family;
  font-weight: $font-weight;
  line-height: $font-line-height;
  font-size: $font-size;

  @include md {
    line-height: $font-line-height-sm;
    font-size: $font-size-sm;
  }
}
